import React, { Component } from 'react';

const styleBGPromo  = {
    backgroundImage     : `url(asset/img/bg_promo.jpg)`,
};

const Pricing = () => {
    
    return (
        <section id="pricing" className="ftco-section">
            <div className="container">
                <div className="row justify-content-center mb-5 pb-3">
                    <div className="col-md-7 heading-section text-center ftco-animate">
                        <h2 className="mb-4">Pricing</h2>
                        <p>
                            we believe in transparent and fair pricing for all our services. 
                            Our goal is to provide you with exceptional value and outstanding results.
                        </p>
                    </div>
                </div>                
                <div className="row">

                    <div className="col-md-4 ftco-animate">
                        <div className="pricing-entry pb-5 text-center">
                            <div>
                                <h3 className="mb-4">Lip Tattoo</h3>
                            </div>
                            <ul>
                                <li>
                                    Lib Blush
                                    <span className="per">(2 sessions)</span> - <span className="price">$400</span>
                                </li>
                                <li>
                                Color Correction
                                    <span className="per">(2 sessions)</span> - <span className="price">$400</span>
                                </li>
                                <li>
                                    Retouch
                                    <span className="per">(1 session)</span> - <span className="price">$150</span>
                                </li>
                            </ul>
                            <p className="button text-center"><a href="https://www.vagaro.com/getredwithme" className="btn btn-primary btn-outline-primary px-4 py-3">Book now</a></p>
                        </div>
                    </div>

                    <div className="col-md-4 ftco-animate">
                        <div className="pricing-entry pb-5 text-center active">
                            <div>
                                <h3 className="mb-4">Brow Sculpting</h3>
                            </div>
                            <ul>
                                <li>
                                    Microblading
                                    <span className="per">(2 sessions)</span> - <span className="price">$400</span>
                                </li>
                                <li>
                                    Ombre Brows
                                    <span className="per">(2 sessions)</span> - <span className="price">$400</span>
                                </li>
                                <li>
                                Combination Technique
                                    <span className="per">(2 sessions)</span> - <span className="price">$450</span>
                                </li>
                                <li>
                                    Brow Shading
                                    <span className="per">(2 sessions)</span> - <span className="price">$400</span>
                                </li>
                                <li>
                                    Retouch
                                    <span className="per">(1 session)</span> - <span className="price">$150</span>
                                </li>
                            </ul>
                            <p className="button text-center"><a href="https://www.vagaro.com/getredwithme" className="btn btn-primary btn-outline-primary px-4 py-3">Book now</a></p>
                        </div>
                    </div>
                    
                    <div className="col-md-4 ftco-animate">
                        <div className="pricing-entry  pb-5 text-center">
                            <div>
                                <h3 className="mb-4">Eyeliner Tattoo</h3>
                            </div>
                            <ul>
                                <li>
                                    Classic
                                    <span className="per">(2 sessions)</span> - <span className="price">$250</span>
                                </li>
                                <li>
                                    Winged
                                    <span className="per">(2 sessions)</span> - <span className="price">$300</span>
                                </li>
                                <li>
                                    Retouch
                                    <span className="per">(1 session)</span> - <span className="price">$150</span>
                                </li>
                            </ul>
                            <p className="button text-center"><a href="https://www.vagaro.com/getredwithme" className="btn btn-primary btn-outline-primary px-4 py-3">Book now</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Pricing;

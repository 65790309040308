import React, { Component } from 'react';

const Services = () => {
    
    return (
        <section id="services" className="ftco-section">
            <div className="container">

                <div className="row justify-content-center mb-5 pb-3">
                    <div className="col-md-7 heading-section text-center ftco-animate">
                        <h2 className="mb-4">Our Services</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 ftco-animate">
                        <div className="media d-block text-center block-6 services">
                        <div className="icon d-flex mb-3"><span className="flaticon-facial-treatment"></span></div>
                        <div className="media-body">
                            <h3 className="heading">Transform Your Brows with Expert Brow Sculpting</h3>
                            <p>                        
                                Our brow sculpting service is designed to give you perfectly shaped, natural-looking eyebrows. 
                                Using advanced techniques, we meticulously shape and define your brows to complement your facial 
                                features and enhance your natural beauty. 
                                Whether you desire a bold, dramatic look or a subtle, refined arch, our skilled artists will work 
                                with you to achieve your ideal brow shape. 
                                Enjoy long-lasting results that keep your brows looking flawless day after day.
                            </p>
                        </div>
                        </div>      
                    </div>
                    <div className="col-md-4 ftco-animate">
                        <div className="media d-block text-center block-6 services">
                        <div className="icon d-flex mb-3"><span className="flaticon-cosmetics"></span></div>
                        <div className="media-body">
                            <h3 className="heading">Achieve Luscious Lips with Professional Lip Tattooing</h3>
                            <p>                    
                                Enhance the color, shape, and fullness of your lips with our professional lip tattoo service. 
                                Our experienced technicians use precise techniques to create a natural-looking lip tint that 
                                enhances your smile and complements your skin tone. 
                                Say goodbye to daily lipstick application and enjoy the convenience of beautifully tinted lips that last for years. 
                                Our lip tattooing process is safe, minimally invasive, and tailored to meet your individual aesthetic goals.
                            </p>
                        </div>
                        </div>      
                    </div>
                    <div className="col-md-4 ftco-animate">
                        <div className="media d-block text-center block-6 services">
                        <div className="icon d-flex mb-3"><span className="flaticon-curl"></span></div>
                        <div className="media-body">
                            <h3 className="heading">Perfect Your Look with Custom Eyeliner Tattooing.</h3>
                            <p>                    
                                Our eyeliner tattoo service offers the ultimate solution for effortlessly defined eyes. 
                                Whether you prefer a subtle enhancement or a more dramatic look, our expert artists will 
                                create a customized eyeliner that suits your style and enhances your eye shape. 
                                Enjoy smudge-proof, long-lasting eyeliner that stays perfect from morning to night. 
                                Our gentle, precise application ensures a comfortable experience with stunning results 
                                that save you time and effort in your daily makeup routine.
                            </p>
                        </div>
                        </div>    
                    </div>
                </div>
            </div>
            <div>
            <p className="button text-center"><a href="https://www.vagaro.com/getredwithme" className="btn btn-primary  px-4 py-3">Book now!</a></p>
            </div>
        </section>
    );
};

export default Services;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { scrollTo } from '../utils/common';

const TopNav = () => {
    
    return (
        <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
            <div className="container">
            {/*
            <a className="navbar-brand" href="/">get<span style={{"color": "red"}}>red</span>withme</a>
            */}
            <a className="navbar-brand" href="/">
                <img src="asset/img/logo.png" className="img-fluid" alt="Get Red With Me" />
            </a>            
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="oi oi-menu"></span> Menu
            </button>

            <div className="collapse navbar-collapse" id="ftco-nav">
                <ul className="navbar-nav ml-auto">
                <li className="nav-item active"><a href="/" className="nav-link">Home</a></li>
                <li className="nav-item"><Link to="/" className="nav-link" onClick={() => scrollTo('about')}>About</Link></li>
                <li className="nav-item"><Link to="/" className="nav-link" onClick={() => scrollTo('services')}>Services</Link></li>
                <li className="nav-item"><Link to="/" className="nav-link" onClick={() => scrollTo('work')}>Work</Link></li>
                <li className="nav-item"><Link to="/" className="nav-link" onClick={() => scrollTo('pricing')}>Pricing</Link></li>
                {/* 
                <li className="nav-item"><a href="blog.html" className="nav-link">Blog</a></li>
                */}
                <li className="nav-item"><Link to="/" className="nav-link" onClick={() => scrollTo('contact')}>Contact</Link></li>
                </ul>
            </div>
            </div>
        </nav>
    );
};

export default TopNav;

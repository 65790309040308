import React, { Component } from 'react';

const styleBGPromo  = {
    backgroundImage     : `url(asset/img/bg_promo.jpg)`,
};

const Partners = () => {
    
    return (
        <section className="ftco-partner bg-light">
            <div className="container">
                <div className="row partner justify-content-center">
                    <div className="col-md-10">
                        <div className="row">
                        <div className="col-md-3 ftco-animate">
                            <a href="#" className="partner-entry">
                                <img src="asset/img/partner-marapro.jpg" className="img-fluid" alt="Mara Pro" />
                            </a>
                        </div>
                        <div className="col-md-3 ftco-animate">
                            <a href="#" className="partner-entry">
                                <img src="asset/img/partner-loreal.jpg" className="img-fluid" alt="Loreal" />
                            </a>
                        </div>
                        <div className="col-md-3 ftco-animate">
                            <a href="#" className="partner-entry">
                                <img src="asset/img/partner-christiandior.jpg" className="img-fluid" alt="Christian Dior" />
                            </a>
                        </div>
                        <div className="col-md-3 ftco-animate">
                            <a href="#" className="partner-entry">
                                <img src="asset/img/partner-mischo.jpg" className="img-fluid" alt="Mischo" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    );
};

export default Partners;

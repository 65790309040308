import React, { Component } from 'react';

import LayoutMaster from '../../layout/LayoutMaster';
import About from './About';
import Services from './Services';
import Experts from './Experts';
import Promo from './Promo';
import OurWork from './OurWork';
import Partners from './Partners';
import Pricing from './Pricing';
import Stats from './Stats';
import Blog from './Blog';
import ContactUs from './ContactUs';

const HomeMain = () => {
    
    return (
        <LayoutMaster>
            <About />
            <Services />
            {/*
            <Experts />
            */}
            <Promo />
            <OurWork />
            <Partners />
            <Pricing />
            {/*
            <Stats />
            <Blog />
            */}
            <ContactUs />
        </LayoutMaster>
    );
};

export default HomeMain;

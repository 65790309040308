import React, { Component, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import Message from '../../component/Message';

import { CONTACT_US_VALIDATION_FAILED, SEND_EMAIL_CONTACT_US } from '../../store/home/actions';

interface Props {
    message     : string,
    messageType : string
  }

const ContactUs: React.FC<Props>  = (props) => {
    
    const dispatch = useDispatch();

    const [inputFirstName, setInputFirstName]   = useState('');
    const [inputLastName, setInputLastName]     = useState('');
    const [inputEmail, setInputEmail]           = useState('');
    const [inputMessage, setInputMessage]       = useState('');

    const handleChangeFirstName = (event:any) => {
        setInputFirstName(event.target.value);
    };
    const handleChangeLastName = (event:any) => {
        setInputLastName(event.target.value);
    };
    const handleChangeEmail = (event:any) => {
        setInputEmail(event.target.value);
    };
    const handleChangeMessage = (event:any) => {
        setInputMessage(event.target.value);
    };

    //////////////////////
    // handleContactUs()
    //////////////////////
    const handleContactUs = () => {
        console.log('handleContactUs()');

        if (inputFirstName == null || inputFirstName == '' ||
            inputEmail == null || inputEmail == '' ||
            inputMessage == null || inputMessage == '') {
            dispatch({
                type    : CONTACT_US_VALIDATION_FAILED,
                payload : {},
            });  
            return;
        };

        var fullName = inputFirstName+ ' '+ inputLastName; 

        var data = {
            appName : process.env.REACT_APP_APP_NAME,
            name    : fullName,
            email   : inputEmail,
            message : inputMessage,
        };
        
        dispatch({
            type    : SEND_EMAIL_CONTACT_US,
            payload : data,
        });  
    };

    return (
        <section id="contact" className="ftco-section ftco-appointment">
            <div className="overlay"></div>
            <div className="container">
                <div className="row d-md-flex align-items-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-4 d-flex align-self-stretch ftco-animate">
                        <div className="appointment-info text-center p-5">
                            <div className="mb-4">
                                <h3 className="mb-3">Address</h3>
                                <p>	29255 Mission Blvd., Hayward, CA 94544</p>
                            </div>
                            <div className="mb-4">
                                <h3 className="mb-3">Phone</h3>
                                <p className="day"><strong>(510) 875-4256</strong></p>
                            </div>
                            <div>
                                <h3 className="mb-3">Business Hours</h3>
                                <p className="day"><strong>Monday - Friday</strong></p>
                                <span>03:30pm - 08:00pm</span>

                                <p className="day" style={{paddingTop: 5}}><strong>Saturday / Sunday</strong></p>
                                <span>08:00am - 04:00pm</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 appointment pl-md-5 ftco-animate">
                        <h3 className="mb-3">Contact Us</h3>
                        <div className="contact-message">
                            <Message type={props.messageType} text={props.message} />
                        </div>
                        <div className="appointment-form">
                            <div className="row form-group d-flex">
                                <div className="col-md-6">
                                    <input id="firstName" name="firstName" type="text" className="form-control" placeholder="First Name" value={inputFirstName} onChange={handleChangeFirstName} />
                                </div>
                                <div className="col-md-6">
                                    <input  id="lastName" name="lastName" type="text" className="form-control" placeholder="Last Name"value={inputLastName} onChange={handleChangeLastName} />
                                </div>
                            </div>
                            <div className="row form-group d-flex">
                                <div className="col-md-6">
                                    <div className="input-wrap">
                                        <div className="icon"><span className="ion-md-mail"></span></div>
                                        <input id="email" name="email" type="text" className="form-control" placeholder="Email" value={inputEmail} onChange={handleChangeEmail} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                <div className="icon"><span className="ion-md-phone-portrait"></span></div>
                                    <input type="text" className="form-control" placeholder="Phone" />
                                </div>
                            </div>
                            <div className="form-group">
                                <textarea id="message" name="message" cols={30} rows={3} className="form-control" placeholder="Message" value={inputMessage} onChange={handleChangeMessage} ></textarea>
                            </div>
                            <div className="form-group">
                                <input type="submit" value="Submit" className="btn btn-white btn-outline-white py-3 px-4" onClick={handleContactUs} />
                            </div>
                        </div>
                    </div>    			
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state: any) => ({
    message     : state.homeReducer.message,
    messageType : state.homeReducer.messageType,
});
  
//export default ContactUs;
export default connect(mapStateToProps)(ContactUs);

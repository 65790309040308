import React, { Component } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { GET_POSTS } from '../store/adm/actions';
import { scrollTo } from '../utils/common';

const imgLogo  = "/asset/img/logo_header.png";

import Landing from './Landing';

const Header = () => {
    
    const dispatch = useDispatch();
    
    //////////////////////
    // loadPosts()
    //////////////////////
    function loadPosts() {
        console.log('loadPosts()');
        var data = {
            app_id : 1,
        };

        dispatch({
            type    : GET_POSTS,
            payload : data,
        });
    }

    return (
        <Landing />
    );
};

export default Header;

export const CONTACT_US_VALIDATION_FAILED= 'CONTACT_US_VALIDATION_FAILED';
export const SEND_EMAIL_CONTACT_US      = 'SEND_EMAIL_CONTACT_US';
export const SEND_EMAIL_SUCCESS         = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE         = 'SEND_EMAIL_FAILURE';

export const sendEmailContactUs = (data: any) => ({
  type    : SEND_EMAIL_CONTACT_US,
  payload : data,
});

export const sendEmailSuccess = () => ({
  type    : SEND_EMAIL_SUCCESS,
});

export const sendEmailFailure = (error: string) => ({
  type    : SEND_EMAIL_FAILURE,
  payload : error,
});

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import AppRoutes from './routes/AppRoutes';


import './asset/css/style.css';
import './asset/css/app.css'; // custom

import './asset/css/open-iconic-bootstrap.min.css';
import './asset/css/animate.css';
import './asset/css/owl.carousel.min.css';
import './asset/css/owl.theme.default.min.css';
import './asset/css/magnific-popup.css';
import './asset/css/aos.css';
import './asset/css/ionicons.min.css';
import './asset/css/bootstrap-datepicker.css';
import './asset/css/jquery.timepicker.css';
import './asset/css/flaticon.css';
import './asset/css/icomoon.css';


class App extends Component {

  ///////////////////////////
  // componentDidMount()
  ///////////////////////////
  componentDidMount() {
    //console.log('App.componentDidMount() begin');
  }

  ///////////////////////////
  // render()
  ///////////////////////////
  render() { 
    return (
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    );
  }

}

export default App;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { scrollTo } from '../../utils/common';

const styleBGPromo  = {
    backgroundImage     : `url(asset/img/promo-lady.jpg)`,
};

const Promo = () => {
    
    return (
        <section className="ftco-section ftco-discount img" style={styleBGPromo}>
            <div className="overlay"></div>
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-md-5 discount ftco-animate">
                        <h3>Soft Opening Promo</h3>
                        <h2 className="mb-4">$50 Off All Permanent Makeup Services</h2>
                        <p className="mb-4">
                            We’re excited to have you discover our exceptional beauty services. 
                            To celebrate your first visit, we’re offering a special discount exclusively for new customers!
                        </p>
                        <p><a href="https://www.vagaro.com/getredwithme" className="btn btn-white btn-outline-white px-4 py-3">Book Now</a></p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Promo;

import React, { Component } from 'react';

const About = () => {
    
    return (
        <section className="ftco-section">
            <div id="about" className="container">
                <div className="row d-flex">
                    <div className="col-md-6 d-flex ftco-animate">
                        <img src="asset/img/about.jpg" className="img-fluid" alt="About" />
                    </div>
                    <div className="col-md-6 pl-md-5 ftco-animate">
                        <h2 className="mb-4">Welcome to Get <span style={{color: "red"}}>Red</span> With Me</h2>
                        <p>
                            At <b>Get Red With Me</b>, we are passionate about enhancing your natural beauty with precision and artistry. 
                            Specializing in brow sculpting, lip tattoo, and eyeliner tattoo services, our mission is to help you look and feel your best every day.
                        </p>
                        <p>
                            Founded by <b>Ynna Red Del Rosario</b>, a seasoned professional with extensive years of experience in the beauty industry, 
                            <b>GetRedWithMe</b> was created to provide high-quality, personalized beauty treatments in a welcoming and relaxing environment. 
                            Our journey began with a vision to combine the latest techniques with exceptional customer care, ensuring each client receives 
                            a tailored experience that exceeds their expectations.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;

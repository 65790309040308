import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomeMain from '../pages/home/HomeMain';

class AppRoutes extends Component {
    render() {
        return (
            <Router>
                <Suspense fallback={<>Loading...</>}>
                    <Switch>
                        <Route exact path="/" component={HomeMain} />
                    </Switch>
                </Suspense>
            </Router>
        );
    }
}

export default AppRoutes;

import { all }  from 'redux-saga/effects';
import admSaga  from './adm/saga';
import homeSaga  from './home/saga';

// Here you can include all the saga which you write for components
export default function* rootSaga(){
  yield all([
    admSaga(),
    homeSaga(),
  ]);
}


///////////////////
// scrollTo()
///////////////////
export function scrollTo(elementId:string) {
  console.log('common.scrollTo()');
  var element = document.getElementById(elementId);
  if (element != null) {
      element.scrollIntoView({behavior: "smooth", block: "end", inline: "end"});
  }
}

import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import $ from 'jquery';

import Header from './Header';
import Landing from './Landing';
import TopNav from './TopNav';
import Footer from './Footer';

type Props = {
    children: React.ReactNode
 }

class LayoutMaster extends Component<Props> {
    
    ///////////////////////////
    // componentDidMount()
    ///////////////////////////
    componentDidMount() { 
        //console.log('LayoutMaster.componentDidMount()');
    } 

    
    ///////////////////////////
    // render()
    ///////////////////////////
    render() { 

        return (
            <>
                <Header />
                <TopNav />
                <ToastContainer />

                {this.props.children}

                <Footer />
            </>
        );
    }
}

export default LayoutMaster;

import Message from '../../component/Message';
import {  CONTACT_US_VALIDATION_FAILED,
          SEND_EMAIL_CONTACT_US, 
          SEND_EMAIL_SUCCESS, 
          SEND_EMAIL_FAILURE } from './actions';

       
const initialState = {
  loading     : false,
  message     : '',   
  messageType : '',
}

//////////////////////////
// Reducer
//////////////////////////
function Reducer(state = initialState, action: any) {
  
  switch (action.type) {

    case CONTACT_US_VALIDATION_FAILED:
      return { 
        ...state, 
        messageType : 'error',   
        message     : 'Please enter your name, email and message.',
      };

    case SEND_EMAIL_CONTACT_US:
      return { 
        ...state, 
        loading: true, 
      };

    case SEND_EMAIL_SUCCESS:
      return { 
        ...state, 
        messageType : 'success',   
        message     : 'Thank you for your message. We\'ll get back to you soon! Promise.',
      };

    case SEND_EMAIL_FAILURE:
      return { 
        ...state, 
        loading: false, 
        messageType : 'error',   
        message     : 'Sorry, an issue was encountered while processing your message.',
      };
      
    default:
      return state;
  }
}

export default Reducer;

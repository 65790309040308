import React, { Component } from 'react';

const styleBGPromo  = {
    backgroundImage     : `url(asset/img/bg_promo.jpg)`,
};

const OurWork = () => {
    
    return (
        <section id="work" className="ftco-section">
            <div className="container">
                <div className="row justify-content-center mb-5 pb-3">
                    <div className="col-md-7 heading-section text-center ftco-animate">
                        <h2 className="mb-4">Our Work</h2>
                        <p>
                            We pride ourselves on delivering exceptional results that enhance the natural beauty of our clients. Our portfolio is a testament to the skill, precision, and artistry that go into every service we provide. 
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 ftco-animate">
                        <a href="#" className="work-entry">
                            <img src="asset/img/work-ombre-brows.jpg" className="img-fluid" alt="Ombre Brows" />
                            <div className="info d-flex align-items-center">
                                <div>
                                    {/*
                                    <div className="icon mb-4 d-flex align-items-center justify-content-center">
                                        <span className="icon-search"></span>
                                    </div>
                                    */}
                                    <h3>Ombre Brows</h3>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4 ftco-animate">
                        <a href="#" className="work-entry">
                            <img src="asset/img/work-eyebrow-shading.jpg" className="img-fluid" alt="Eyebrow Shading" />
                            <div className="info d-flex align-items-center">
                                <div>
                                    {/*
                                    <div className="icon mb-4 d-flex align-items-center justify-content-center">
                                        <span className="icon-search"></span>
                                    </div>
                                    */}
                                    <h3>Eyebrow Shading</h3>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4 ftco-animate">
                        <a href="#" className="work-entry">
                            <img src="asset/img/work-combination-technique.jpg" className="img-fluid" alt="Combination Technique" />
                            <div className="info d-flex align-items-center">
                                <div>
                                    {/*
                                    <div className="icon mb-4 d-flex align-items-center justify-content-center">
                                        <span className="icon-search"></span>
                                    </div>
                                    */}
                                    <h3>Combination Technique</h3>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 ftco-animate">
                        <a href="#" className="work-entry">
                            <img src="asset/img/work-lip-blush.jpg" className="img-fluid" alt="Lip Blush" />
                            <div className="info d-flex align-items-center">
                                <div>
                                    {/*
                                    <div className="icon mb-4 d-flex align-items-center justify-content-center">
                                        <span className="icon-search"></span>
                                    </div>
                                    */}
                                    <h3>Lip Blush</h3>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4 ftco-animate">
                        <a href="#" className="work-entry">
                            <img src="asset/img/work-microblading.jpg" className="img-fluid" alt="Microblading" />
                            <div className="info d-flex align-items-center">
                                <div>
                                    {/*
                                    <div className="icon mb-4 d-flex align-items-center justify-content-center">
                                        <span className="icon-search"></span>
                                    </div>
                                    */}
                                    <h3>Microblading</h3>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4 ftco-animate">
                        <a href="#" className="work-entry">
                        <img src="asset/img/work-lip-blush2.jpg" className="img-fluid" alt="Lip Blush" />
                            <div className="info d-flex align-items-center">
                                <div>
                                    {/*
                                    <div className="icon mb-4 d-flex align-items-center justify-content-center">
                                        <span className="icon-search"></span>
                                    </div>
                                    */}
                                    <h3>Lip Blush</h3>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default OurWork;

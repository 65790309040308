import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { scrollTo } from '../utils/common';

const styleImg  = {
    //backgroundImage     : `url(asset/img/landing-ladyred.jpg)`,
    backgroundImage     : `url(asset/img/landing-bw.jpg)`,
};

const Landing = () => {
    
    return (
        <div className="hero-wrap js-fullheight" style={styleImg} data-stellar-background-ratio="0.5">
            <div className="overlay"></div>
            <div className="container">
                <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center" data-scrollax-parent="true">
                <div className="col-md-8 ftco-animate text-center" data-scrollax=" properties: { translateY: '70%' }">
                    <div className="icon">
                        <a href="/" className="logo">
                            {/*
                            <span className="flaticon-flower"></span>
                            */}
                            <h1>Enhance your beauty!</h1>
                        </a>
                    </div>
                    <h1 className="mb-4" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">Get <span style={{"color": "red"}}>Red</span> With Me</h1>
                    {/*
                    <p className="mb-5" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"><a href="/">getredwithme.com</a></p>
                    */}
                    <p data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"><Link to="/" className="btn btn-white btn-outline-white px-4 py-3" onClick={() => scrollTo('services')}>View Our Services</Link></p>
                    
                </div>
                </div>
            </div>
        </div>
    );
};

export default Landing;

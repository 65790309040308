import React, { Component } from 'react';

const styleFooterBlog  = {
    backgroundImage     : `url(asset/img/blog_footer.jpg)`,
};

class Footer extends Component {
    
    ///////////////////////////
    // componentDidMount()
    ///////////////////////////
    componentDidMount() { 
        //console.log('Footer.componentDidMount()');
    } 

    
    ///////////////////////////
    // render()
    ///////////////////////////
    render() { 

        return (
            <footer className="ftco-footer ftco-section img">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row mb-5">
                    <div className="col-md-6">
                        <div className="ftco-footer-widget mb-4">
                        <h2 className="ftco-heading-2">About Us</h2>
                        <p>
                            We believe that beauty is about feeling confident and comfortable in your own skin. Our team is dedicated to helping 
                            you achieve your desired look through meticulous attention to detail, advanced techniques, and the highest standards of safety and hygiene.
                            </p>
                        <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                            <li className="ftco-animate"><a href="https://www.tiktok.com/@getredwithmee" target="_new"><img src="asset/img/icon-tiktok.svg" className="img-fluid" alt="About" /></a></li>
                            <li className="ftco-animate"><a href="https://www.facebook.com/getredwithme" target="_new"><span className="icon-facebook"></span></a></li>
                            <li className="ftco-animate"><a href="https://www.instagram.com/getredwithmee/" target="_new"><span className="icon-instagram"></span></a></li>
                        </ul>
                        </div>
                    </div>
                    {/*
                    <div className="col-md-4">
                        <div className="ftco-footer-widget mb-4">
                        <h2 className="ftco-heading-2">Recent Blog</h2>
                        <div className="block-21 mb-4 d-flex">
                            <a className="blog-img mr-4" style={styleFooterBlog}></a>
                            <div className="text">
                            <h3 className="heading"><a href="#">Even the all-powerful Pointing has no control about</a></h3>
                            <div className="meta">
                                <div><a href="#"><span className="icon-calendar"></span> July 12, 2018</a></div>
                                <div><a href="#"><span className="icon-person"></span> Admin</a></div>
                                <div><a href="#"><span className="icon-chat"></span> 19</a></div>
                            </div>
                            </div>
                        </div>
                        <div className="block-21 mb-6 d-flex">
                            <a className="blog-img mr-4" style={styleFooterBlog}></a>
                            <div className="text">
                            <h3 className="heading"><a href="#">Even the all-powerful Pointing has no control about</a></h3>
                            <div className="meta">
                                <div><a href="#"><span className="icon-calendar"></span> July 12, 2018</a></div>
                                <div><a href="#"><span className="icon-person"></span> Admin</a></div>
                                <div><a href="#"><span className="icon-chat"></span> 19</a></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    */}
                    <div className="col-md-6">
                        <div className="ftco-footer-widget mb-4">
                            <h2 className="ftco-heading-2">Have a Questions?</h2>
                            <div className="block-23 mb-3">
                            <ul>
                                <li><span className="icon icon-map-marker"></span><span className="text">29255 Mission Blvd., Hayward, CA 94544</span></li>
                                <li><a href="#"><span className="icon icon-phone"></span><span className="text">(510) 875-4256</span></a></li>
                                <li><a href="mailto:getredwithynna@gmail.com"><span className="icon icon-envelope"></span><span className="text">getredwithynna@gmail.com</span></a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12 text-center">

                        <p className='credit'>
                            Developed by <a href="https://instantapp.dev" target="_blank">InstantApp</a> &copy; 2024
                        </p>
                    </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;

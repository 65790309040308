import Message from '../../component/Message';
import { toast } from 'react-toastify';
import { Post } from '../../types';

import {  
          GET_POST_BY_ID_SUCCESS,
          GET_POST_BY_ID_FAILED,
          GET_POST_BY_SLUG_SUCCESS,
          GET_POST_BY_SLUG_FAILED,
          GET_POSTS_SUCCESS,
          GET_POSTS_FAILED,
          SAVE_POST_VALIDATION_FAILED,
          SAVE_POST, 
          SAVE_POST_SUCCESS, 
          SAVE_POST_FAILED } from './actions';

       
const initialState = {
  loading     : false,
  message     : '',   
  messageType : '',
  alPost      : [],
  post        : null,
}

//////////////////////////
// Reducer
//////////////////////////
function Reducer(state = initialState, action: any) {
  
  switch (action.type) {

    case GET_POSTS_SUCCESS:
      console.log('GET_POSTS_SUCCESS');
      console.log('action.payload');
      console.log(action.payload);
      return { 
        ...state,
        alPost : action.payload,
      };

    case GET_POSTS_FAILED:
      console.log('GET_POSTS_FAILED');
      var message = 'Sorry, an issue was encountered while retrieving posts.';
      toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
      return { 
        ...state, 
      };
  
    case GET_POST_BY_ID_SUCCESS:
      console.log('GET_POST_BY_ID_SUCCESS');
      console.log('action.payload');
      console.log(action.payload);
      return { 
        ...state,
        post : action.payload,
      };

    case GET_POST_BY_SLUG_SUCCESS:
      console.log('GET_POST_BY_SLUG_SUCCESS');
      console.log('action.payload');
      console.log(action.payload);
      return { 
        ...state,
        post : action.payload,
      };
        
    case SAVE_POST_VALIDATION_FAILED:
      return { 
        ...state, 
        messageType : 'error',   
        message     : 'Please enter the required fields.',
      };

    case SAVE_POST:
      return { 
        ...state, 
        loading: true, 
      };

    case SAVE_POST_SUCCESS:
      console.log('SAVE_POST_SUCCESS');
      var message = 'The post has been saved successfully.';
      toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
      return { 
        ...state, 
        messageType : 'success',   
        message     : message,
      };

    case SAVE_POST_FAILED:
      console.log('SAVE_POST_FAILED');
      var message = 'Sorry, an issue was encountered while saving the post.';
      toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
      return { 
        ...state, 
        loading: false, 
        messageType : 'error',   
        message     : message,
      };
      
    default:
      return state;
  }
}

export default Reducer;
